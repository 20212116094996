import request from '@/util/request'

//首页导航栏
export const navigation = () => {
    return request({
        url: '/api/website/front/index/pageIndex/navigation',
        method: 'GET',
    })
}
//前台一二级导航栏查询
export const getNavigation = () => {
    return request({
        url: '/api/website/websiteList/getNavigation',
        method: 'GET',
    })
}
//新前台一二级导航栏查询(pc端)
export const getNavigationTemplate = (id) => {
    return request({
        url: '/api/website/websiteList/getNavigation/template/' + id,
        method: 'GET',
    })
}
//新前台一二级导航栏查询（移动端）
export const getNavigationTemplateApp = (id) => {
    return request({
        url: '/api/website/websiteList/getNavigation/template/app/' + id,
        method: 'GET',
    })
}
//查询底部数据
export const selectBottom = () => {
    return request({
        url: '/api/website/websiteList/selectBottom',
        method: 'GET',
    })
}
//前端友情链接查询
export const selectLink = () => {
    return request({
        url: '/api/website/websiteList/selectLink',
        method: 'GET',
    })
}
//Banner图查询
export const selectBanner = () => {
    return request({
        url: '/api/website/websiteList/selectBanner',
        method: 'GET',
    })
}
//精品图片查询
export const selectMapDepot = () => {
    return request({
        url: '/api/website/websiteList/selectMapDepot',
        method: 'GET',
    })
}
//首页主体查询（PC端）
export const selectList = () => {
    return request({
        url: '/api/website/websiteList/selectList',
        method: 'GET',
    })
}
//首页主体查询（移动端）
export const selectListApp = () => {
    return request({
        url: '/api/website/websiteList/selectListApp',
        method: 'GET',
    })
}
//导航集合页面
export const selectNavigaionList = (data) => {
    return request({
        url: '/api/website/websiteList/selectNavigaionList',
        method: 'POST',
        data
    })
}
//新闻详情页面
export const websiteListQueryId = (id) => {
    return request({
        url: '/api/website/websiteList/' + id,
        method: 'GET',
    })
}
//二级导航栏主体
export const getNavigationTwoList = () => {
    return request({
        url: '/api/website/websiteList/getNavigationTwoList',
        method: 'GET',
    })
}
//根据导航栏id查询子导航栏
export const getNavigationQueryId = (id) => {
    return request({
        url: '/api/website/websiteList/getNavigation/' + id,
        method: 'GET',
    })
}
//根据租户查询网站信息
export function getTenant() {
    return request({
        url: '/api/system/baseLogin/getTenant',
        method: 'get',
    })
}
export function getTenantById(code) {
    return request({
        url: '/api/system/baseLogin/front/getWebInfo?website='+code,
        method: 'get',
    })
}
//富文本回显
export function getTextarea(data) {
    return request({
        url: '/api/file/getUploaderData',
        method: 'POST',
        data: data
    })
}
//导航栏新闻查询
export const websiteListGetJournalismId = (id) => {
    return request({
        url: '/api/website/websiteList/getJournalismId/' + id,
        method: 'GET',
    })
}
// 获取租户的logo，名称
export const getTenantInfo = (data) => {
    return request({
        url: `/api/system/baseLogin/front/getTenantTemplate/${data.tid}`,
        method: 'get',
    })
}
// 获取主题色
export const websiteTemplate = (id) => {
    return request({
        url: `/api/website/websiteTemplate/${id}`,
        method: 'get',
    })
}

//获取人员类型模本
export const baseInfoPersonnelFormPage = (data) => {
    return request({
        url: `/api/baseInfo/personnel/form/page?currentPage=${data.currentPage}&pageSize=${data.pageSize}&organizeId=${data.organizeId}&infoCategory=${data.infoCategory}`,
        method: 'get',
        data
    })
}

//人员类别下拉
export const baseInfoInfoCategoryOps = (category) => {
    return request({
        url: `/api/baseInfo/infoCategory/ops/${category}`,
        method: 'get',
    })
}

//新增修改人员
export const baseInfoInfoCategoryEdit = (data) => {
    return request({
        url: '/api/baseInfo/personnel/edit',
        method: 'post',
        data: data
    })
}
//人员列表-分页
export const baseInfoInfoPersonnelPage = (data) => {
    return request({
        url: `/api/baseInfo/personnel/page?organizeId=${data.organizeId}&currentPage=${data.currentPage}&pageSize=${data.pageSize}`,
        method: 'get',
    })
}
