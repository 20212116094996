import Vue from 'vue';
import VueRouter from 'vue-router';
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(VueRouter)
export default new VueRouter({
  // "linkActiveClass":"actived",
  "linkExactActiveClass": "actived",
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/pages/home.vue'),
      meta: { index: 0 },//meta对象的index用来定义当前路由的层级,由小到大,由低到高
      redirect: '/index',
      children: [
        {
          path: '/index',
          name: 'index',
          component: () => import('@/pages/index.vue'),
          meta: { index: 1 }
        },
        {
          path: '/product',
          name: 'product',
          component: () => import('@/pages/product/index.vue'),
          meta: { index: 2 },
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/events',
          name: 'events',
          component: () => import('@/pages/events/index.vue'),
          meta: { index: 2 },
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/events-detail/:id',
          name: 'events-detail',
          component: () => import('@/pages/events/detail.vue'),
          meta: { index: 2 },
          props: true,
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/events-signup',
          name: 'events-signup',
          component: () => import('@/pages/events/signup.vue'),
          meta: { index: 2 },
          props: true,
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/events-orderinfo',
          name: 'events-orderinfo',
          component: () => import('@/pages/events/orderinfo.vue'),
          meta: { index: 2 },
          props: true,
        },
        {
          path: '/train',
          name: 'train',
          component: () => import('@/pages/train/index.vue'),
          meta: { index: 2 },
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/train-detail/:id',
          name: 'train-detail',
          component: () => import('@/pages/train/detail.vue'),
          meta: { index: 2 },
          props: true,
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/train-signup',
          name: 'train-signup',
          component: () => import('@/pages/train/signup.vue'),
          meta: { index: 2 },
          props: true,
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/train-orderinfo',
          name: 'train-orderinfo',
          component: () => import('@/pages/train/orderinfo.vue'),
          meta: { index: 2 },
          props: true,
        },
        {
          path: '/course',
          name: 'course',
          component: () => import('@/pages/course/index.vue'),
          meta: { index: 2 },
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/course-signup',
          name: 'course-signup',
          component: () => import('@/pages/course/signup.vue'),
          meta: { index: 2 },
          props: true,
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/course-signup-detail',
          name: 'course-signup-detail',
          component: () => import('@/pages/course/signupDetail.vue'),
          meta: { index: 2 },
          props: true,
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/course-detail/:id',
          name: 'course-detail',
          component: () => import('@/pages/course/detail.vue'),
          meta: { index: 2 },
          props: true,
          // meta: {
          //   requireAuth: true
          // },
        },
        // {
        //   path: '/myCourse-detail',
        //   name: 'course-detail',
        //   component: () => import('@/pages/course/myCourseDetail.vue'),
        //   meta: { index: 2 },
        //   props:true,
        //   // meta: {
        //   //   requireAuth: true
        //   // },
        // },
        {
          path: '/news',
          name: 'news',
          component: () => import('@/pages/news/index.vue'),
          meta: { index: 2 },
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/partners',
          name: 'partners',
          component: () => import('@/pages/partners/index.vue'),
          meta: { index: 2 },
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/partners-apply/:id',
          name: 'partners-apply',
          component: () => import('@/pages/partners/apply.vue'),
          props: true,
          meta: {
            requireAuth: true
          },
        },
        {
          path: '/result',
          name: 'result',
          props: true,
          component: () => import('@/pages/payment/result')
        },
        {
          path: '/about',
          name: 'about',
          component: () => import('@/pages/about/index.vue'),
          meta: { index: 2 },
          // meta: {
          //   requireAuth: true
          // },
        },
        {
          path: '/solution',
          name: 'solution',
          props: true,
          component: () => import('@/pages/solution/newIndex.vue')
        },
        {
          path: '/sportsInfo',
          name: 'sportsInfo',
          props: true,
          component: () => import('@/pages/sportsInfo/index.vue')
        },
        {
          path: '/sportsInfo-details',
          name: 'sportsInfo-details',
          props: true,
          component: () => import('@/pages/sportsInfo/details.vue')
        },
        {
          path: '/serviceCase',
          name: 'serviceCase',
          props: true,
          component: () => import('@/pages/serviceCase/index.vue')
        },
      ]
    },
    {
      path: '/',
      name: '',
      component: () => import('@/pages/news/index.vue'),
      children: [
        {
          path: '/news',
          name: 'news',
          component: () => import('@/pages/news/news.vue')
        },
        {
          path: '/policy',
          name: 'policy',
          component: () => import('@/pages/news/policy.vue')
        },
        {
          path: '/information',
          name: 'information',
          component: () => import('@/pages/news/information.vue')
        },
        {
          path: '/news-detail/:id',
          name: 'news-detail',
          props: true,
          component: () => import('@/pages/news/news-detail.vue')
        }
      ]
    },
    {
      path: '/',
      name: '',
      component: () => import('@/pages/user/index.vue'),
      // meta: {
      //   requireAuth: true
      // },
      children: [
        {
          path: '/user',
          name: 'user',
          props: true,
          component: () => import('@/pages/user/user.vue')
        },
        {
          path: '/members',
          name: 'members',
          props: true,
          component: () => import('@/pages/user/members.vue'),
          meta: {
            requireAuth: true
          },
        },
        {
          path: '/my-certification',
          name: 'myCertification',
          props: true,
          component: () => import('@/pages/user/myCertification.vue'),
          meta: {
            requireAuth: true
          },
        },
        {
          path: '/certify',
          name: 'certify',
          props: true,
          component: () => import('@/pages/user/certify.vue'),
          meta: {
            requireAuth: true
          },
        },
        {
          path: '/participation',
          name: 'participation',
          props: true,
          component: () => import('@/pages/user/participation.vue')
        },
        {
          path: '/professional-certification',
          name: 'professional-certification',
          props: true,
          component: () => import('@/pages/user/professionalCertification.vue')
        },
        {
          path: '/order',
          name: 'order',
          props: true,
          component: () => import('@/pages/user/order')
        },
        {
          path: '/invoiceOpen/:id',
          name: 'invoiceOpen',
          component: () => import('@/pages/user/invoiceOpen.vue'),
          props: true,
          meta: {
            requireAuth: true
          },
        },
        {
          path: '/orderRefund/:id',
          name: 'orderRefund',
          component: () => import('@/pages/user/orderRefund.vue'),
          props: true,
          meta: {
            requireAuth: true
          },
        },
        {
          path: '/order-detail',
          name: 'order-detail',
          props: true,
          component: () => import('@/pages/user/orderDetail')
        },

      ]
    },
    {
      path: '/',
      name: '',
      component: () => import('@/pages/course/myCourse/index'),
      // meta: {
      //   requireAuth: true
      // },
      children: [
        {
          path: '/myCourse-detail',
          name: 'myCourse-detail',
          props: true,
          component: () => import('@/pages/course/myCourse/myCourseDetail')
        },
        {
          path: '/myCourse-exam',
          name: 'myCourse-exam',
          props: true,
          component: () => import('@/pages/course/myCourse/exam')
        },
        {
          path: '/grades',
          name: 'grades',
          props: true,
          component: () => import('@/pages/course/myCourse/grades')
        },
      ]
    },



    {
      path: '/empty-pages',
      name: 'empty-pages',
      props: true,
      component: () => import('@/pages/empty-pages.vue')
    },


    {
      path: '/first-home',
      name: 'first-home',
      component: () => import('@/pages/template/first/first.vue'),
      meta: { index: 0 },
      redirect: '/index',
      children: [
        {
          path: '/template-first',
          name: 'template-first',
          component: () => import('@/pages/template/first/index.vue'),
          meta: { index: 4 }
        },
        {
          path: '/first-list/:name/:id/:type',
          name: 'first-list',
          props: true,
          component: () => import('@/pages/template/first/list.vue'),
          meta: { index: 4 }
        },
        {
          path: '/first-transfer/:name/:id/:type',
          name: 'first-transfer',
          props: true,
          component: () => import('@/pages/template/first/transfer.vue'),
          meta: { index: 4 }
        },
        {
          path: '/first-details',
          name: 'first-details',
          props: true,
          component: () => import('@/pages/template/first/details.vue'),
          meta: { index: 5 }
        }
      ]
    },
    {
      path: '/second-home',
      name: 'second-home',
      component: () => import('@/pages/template/second/second.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-second',
          name: 'template-second',
          component: () => import('@/pages/template/second/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/second-list/:name/:id/:type/:index',
          name: 'second-list',
          props: true,
          component: () => import('@/pages/template/second/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/second-transfer/:name/:id/:type/:index',
          name: 'second-transfer',
          props: true,
          component: () => import('@/pages/template/second/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/second-details',
          name: 'second-details',
          props: true,
          component: () => import('@/pages/template/second/details.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/second2-home',
      name: 'second2-home',
      component: () => import('@/pages/template/second2/second.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-second2',
          name: 'template-second2',
          component: () => import('@/pages/template/second2/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/second2-list/:name/:id/:type/:index',
          name: 'second2-list',
          props: true,
          component: () => import('@/pages/template/second2/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/second2-transfer/:name/:id/:type/:index',
          name: 'second2-transfer',
          props: true,
          component: () => import('@/pages/template/second2/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/second2-details',
          name: 'second2-details',
          props: true,
          component: () => import('@/pages/template/second2/details.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/third-home',
      name: 'third-home',
      component: () => import('@/pages/template/third/third.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-third',
          name: 'template-third',
          component: () => import('@/pages/template/third/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/third-list/:name/:id/:type/:index',
          name: 'third-list',
          props: true,
          component: () => import('@/pages/template/third/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/third-transfer/:name/:id/:type/:index',
          name: 'third-transfer',
          props: true,
          component: () => import('@/pages/template/third/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/third-details',
          name: 'third-details',
          props: true,
          component: () => import('@/pages/template/third/details.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/fourth-home',
      name: 'fourth-home',
      component: () => import('@/pages/template/fourth/fourth.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-fourth',
          name: 'template-fourth',
          component: () => import('@/pages/template/fourth/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/fourth-list/:name/:id/:type',
          name: 'fourth-list',
          props: true,
          component: () => import('@/pages/template/fourth/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/fourth-transfer/:name/:id/:type',
          name: 'fourth-transfer',
          props: true,
          component: () => import('@/pages/template/fourth/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/fourth-details',
          name: 'fourth-details',
          props: true,
          component: () => import('@/pages/template/fourth/details.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/fifth-home',
      name: 'fifth-home',
      component: () => import('@/pages/template/fifth/fifth.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-fifth',
          name: 'template-fifth',
          component: () => import('@/pages/template/fifth/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/fifth-list/:name/:id/:type/:index',
          name: 'fifth-list',
          props: true,
          component: () => import('@/pages/template/fifth/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/fifth-transfer/:name/:id/:type/:index',
          name: 'fifth-transfer',
          props: true,
          component: () => import('@/pages/template/fifth/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/fifth-details',
          name: 'fifth-details',
          props: true,
          component: () => import('@/pages/template/fifth/details.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/sixth-home',
      name: 'sixth-home',
      component: () => import('@/pages/template/sixth/sixth.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-sixth',
          name: 'template-sixth',
          component: () => import('@/pages/template/sixth/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/sixth-list/:name/:id/:type',
          name: 'sixth-list',
          props: true,
          component: () => import('@/pages/template/sixth/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/sixth-transfer/:name/:id/:type',
          name: 'sixth-transfer',
          props: true,
          component: () => import('@/pages/template/sixth/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/sixth-details',
          name: 'sixth-details',
          props: true,
          component: () => import('@/pages/template/sixth/details.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/seventh-home',
      name: 'seventh-home',
      component: () => import('@/pages/template/seventh/seventh.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-seventh',
          name: 'template-seventh',
          component: () => import('@/pages/template/seventh/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/seventh-list/:name/:id/:type/:isP',
          name: 'seventh-list',
          props: true,
          component: () => import('@/pages/template/seventh/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/seventh-transfer/:name/:id/:type/:isP',
          name: 'seventh-transfer',
          props: true,
          component: () => import('@/pages/template/seventh/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/seventh-details',
          name: 'seventh-details',
          props: true,
          component: () => import('@/pages/template/seventh/details.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/eighth-home',
      name: 'eighth-home',
      component: () => import('@/pages/template/eighth/eighth.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-eighth',
          name: 'template-eighth',
          component: () => import('@/pages/template/eighth/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/eighth-list/:name/:id/:type',
          name: 'eighth-list',
          props: true,
          component: () => import('@/pages/template/eighth/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/eighth-transfer/:name/:id/:type',
          name: 'eighth-transfer',
          props: true,
          component: () => import('@/pages/template/eighth/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/eighth-details',
          name: 'eighth-details',
          props: true,
          component: () => import('@/pages/template/eighth/details.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/eighthMobile-home',
      name: 'eighthMobile-home',
      component: () => import('@/pages/template/eighthMobile/eighthMobile.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-eighthMobile',
          name: 'template-eighthMobile',
          component: () => import('@/pages/template/eighthMobile/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/eighthMobile-list/:name/:id/:type',
          name: 'eighthMobile-list',
          props: true,
          component: () => import('@/pages/template/eighthMobile/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/eighthMobile-transfer/:name/:id/:type',
          name: 'eighthMobile-transfer',
          props: true,
          component: () => import('@/pages/template/eighthMobile/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/eighthMobile-details',
          name: 'eighthMobile-details',
          props: true,
          component: () => import('@/pages/template/eighthMobile/details.vue'),
          meta: { index: 6 }
        },
        {
          path: '/eighthMobile-registration/:infoCategory',
          name: 'eighthMobile-registration',
          props: true,
          component: () => import('@/pages/template/eighthMobile/registration.vue'),
          meta: { index: 6 }
        },
        {
          path: '/eighthMobile-myRegistration',
          name: 'eighthMobile-myRegistration',
          props: true,
          component: () => import('@/pages/template/eighthMobile/myRegistration.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/ninth-home',
      name: 'ninth-home',
      component: () => import('@/pages/template/ninth/ninth.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-ninth',
          name: 'template-ninth',
          component: () => import('@/pages/template/ninth/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/ninth-list/:name/:id/:type',
          name: 'ninth-list',
          props: true,
          component: () => import('@/pages/template/ninth/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/ninth-transfer/:name/:id/:type',
          name: 'ninth-transfer',
          props: true,
          component: () => import('@/pages/template/ninth/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/ninth-details',
          name: 'ninth-details',
          props: true,
          component: () => import('@/pages/template/ninth/details.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/tenth-home',
      name: 'tenth-home',
      component: () => import('@/pages/template/tenth/tenth.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-tenth',
          name: 'template-tenth',
          component: () => import('@/pages/template/tenth/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/tenth-list/:name/:id/:type',
          name: 'tenth-list',
          props: true,
          component: () => import('@/pages/template/tenth/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/tenth-transfer/:name/:id/:type',
          name: 'tenth-transfer',
          props: true,
          component: () => import('@/pages/template/tenth/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/tenth-details',
          name: 'tenth-details',
          props: true,
          component: () => import('@/pages/template/tenth/details.vue'),
          meta: { index: 6 }
        },
        {
          path: '/tenth-registration/:infoCategory',
          name: 'tenth-registration',
          props: true,
          component: () => import('@/pages/template/tenth/registration.vue'),
          meta: { index: 6 }
        },
        {
          path: '/tenth-myRegistration',
          name: 'tenth-myRegistration',
          props: true,
          component: () => import('@/pages/template/tenth/myRegistration.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/tenthMobile-home',
      name: 'tenthMobile-home',
      component: () => import('@/pages/template/tenthMobile/tenthMobile.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-tenthMobile',
          name: 'template-tenthMobile',
          component: () => import('@/pages/template/tenthMobile/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/tenthMobile-list/:name/:id/:type',
          name: 'tenthMobile-list',
          props: true,
          component: () => import('@/pages/template/tenthMobile/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/tenthMobile-transfer/:name/:id/:type',
          name: 'tenthMobile-transfer',
          props: true,
          component: () => import('@/pages/template/tenthMobile/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/tenthMobile-details',
          name: 'tenthMobile-details',
          props: true,
          component: () => import('@/pages/template/tenthMobile/details.vue'),
          meta: { index: 6 }
        },
        {
          path: '/tenthMobile-registration/:infoCategory',
          name: 'tenthMobile-registration',
          props: true,
          component: () => import('@/pages/template/tenthMobile/registration.vue'),
          meta: { index: 6 }
        },
        {
          path: '/tenthMobile-myRegistration',
          name: 'tenthMobile-myRegistration',
          props: true,
          component: () => import('@/pages/template/tenthMobile/myRegistration.vue'),
          meta: { index: 6 }
        }
      ]
    },
    {
      path: '/eleventh-home',
      name: 'eleventh-home',
      component: () => import('@/pages/template/eleventh/eleventh.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/template-eleventh',
          name: 'template-eleventh',
          component: () => import('@/pages/template/eleventh/index.vue'),
          meta: { index: 5 }
        },
        {
          path: '/eleventh-list/:name/:id/:type',
          name: 'eleventh-list',
          props: true,
          component: () => import('@/pages/template/eleventh/list.vue'),
          meta: { index: 5 }
        },
        {
          path: '/eleventh-transfer/:name/:id/:type',
          name: 'eleventh-transfer',
          props: true,
          component: () => import('@/pages/template/eleventh/transfer.vue'),
          meta: { index: 5 }
        },
        {
          path: '/eleventh-details',
          name: 'eleventh-details',
          props: true,
          component: () => import('@/pages/template/eleventh/details.vue'),
          meta: { index: 6 }
        },
        {
          path: '/eleventh-registration/:infoCategory',
          name: 'eleventh-registration',
          props: true,
          component: () => import('@/pages/template/eleventh/registration.vue'),
          meta: { index: 6 }
        },
        {
          path: '/eleventh-myRegistration',
          name: 'eleventh-myRegistration',
          props: true,
          component: () => import('@/pages/template/eleventh/myRegistration.vue'),
          meta: { index: 6 }
        }
      ]
    },{
      path: '/currency-home',
      name: 'currency-home',
      component: () => import('@/pages/template/currencyTemplate/currency.vue'),
      meta: { index: 4 },
      redirect: '/index',
      children: [
        {
          path: '/currency',
          name: 'currency',
          component: () => import('@/pages/template/currencyTemplate/index.vue'),
          meta: { index: 5 }
        },
      ]
    },
    {
      path: '/member-card/:data',
      name: 'member-card',
      props: true,
      component: () => import('@/pages/memberCard/memberCard')
    },
    {
      path: '/videoList',
      name: 'videoList',
      props: true,
      component: () => import('@/pages/course/myCourse/vedioList')
    },
  ]
})

