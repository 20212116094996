// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/image/cert.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".auditForm[data-v-35fd8f20]{margin:20px}.auditForm .bold h2[data-v-35fd8f20]{font-size:16px;font-weight:700;padding-bottom:12px;margin-bottom:30px;border-bottom:1px solid #ececec}.auditForm .certifyLogo[data-v-35fd8f20]{display:flex;align-items:center;margin-bottom:18px}.auditForm .certifyLogo img[data-v-35fd8f20]{padding-right:16px;width:44px;height:44px}.auditForm .certItem[data-v-35fd8f20]{padding-bottom:20px;display:flex}.auditForm .certItem .certName[data-v-35fd8f20]{display:inline-block;width:100px;margin-right:20px}.auditForm .failReason[data-v-35fd8f20]{width:490px;height:84px;background:#fff8f8;color:#ff3f35;margin-bottom:30px}.auditForm .failReason .reason[data-v-35fd8f20]{padding:20px 9px}.auditForm[data-v-35fd8f20] .el-input{width:555px}.auditForm[data-v-35fd8f20].avatar-uploader .el-upload{border:1px dashed #d9d9d9;border-radius:6px;cursor:pointer;position:relative;overflow:hidden;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:278px 158px;background-repeat:no-repeat;background-position:50%}.auditForm[data-v-35fd8f20].avatar-uploader .el-upload:hover{border-color:#409eff}.auditForm .avatar-uploader-icon[data-v-35fd8f20]{font-size:28px;color:#8c939d;width:328px;height:208px;line-height:360px;fill:#c2c2c2}.auditForm .avatar-uploader-icon[data-v-35fd8f20] :hover{fill:#409eff}.auditForm .avatar[data-v-35fd8f20]{width:328px;height:208px;display:block}.auditForm .large[data-v-35fd8f20]{width:400px;-o-object-fit:contain;object-fit:contain}.auditForm .auditBtn[data-v-35fd8f20] .el-button{width:104px;height:34px;line-height:9px}.auditForm .auditBtn[data-v-35fd8f20] .el-form-item__content{margin-left:0!important;margin-top:22px}.auditForm[data-v-35fd8f20].el-upload__tip{margin-top:0}.auditForm .download[data-v-35fd8f20],.auditForm .remark[data-v-35fd8f20]{color:#409eff;cursor:pointer}.auditForm .auditImg[data-v-35fd8f20]{width:328px;height:208px}", ""]);
// Exports
module.exports = exports;
