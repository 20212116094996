<template>
  <div class="box">
    <div class="content">
      <div class="main_box">
        <div class="banner">
          <el-carousel :interval="3000" arrow="always" height="310px">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <div class="title">{{ item.journalisTitle }}</div>
              <el-image class="img" :src="echoImg(item.bannerUrl)" @click="goDetails('', item.id)"
                fit="cover"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="listBox" v-for="(item, index) in subjectList" :key="index" :class="'list_box' + index">
          <ul class="ul" :style="'border-bottom:1px solid' + color">
            <div class="li">
              <template v-if="index == 0 || index % 5 == 3">
                <div v-for="(items, index1) in item.witemVo" :key="index1">
                  <li v-if="index1 <= 1" :class="items.isCheck ? 'active' : ''"
                    :style="items.isCheck ? 'background:' + color : ''" v-on:mouseover="changeActive(index, index1)">{{
                      items.navigationName }}</li>
                </div>
              </template>
              <template v-else-if="index == 1">
                <div>
                  <li class="active" :style="'background:' + color" v-on:mouseover="changeActive(index, index1)">关于协会
                  </li>
                </div>
              </template>
              <template v-else>
                <div v-for="(items, index1) in item.witemVo" :key="index1">
                  <li v-if="index1 <= 4" :class="items.isCheck ? 'active' : ''"
                    :style="items.isCheck ? 'background:' + color : ''" v-on:mouseover="changeActive(index, index1)">{{
                      items.navigationName }}</li>
                </div>
              </template>
            </div>
            <div class="more" v-if="index == 1"
              @click="more(dataInfo.navigationName, dataInfo.id, dataInfo.navigationType)">更多>>
            </div>
            <div class="more" v-else @click="more(item.navigationName, item.id, item.navigationType)">更多>></div>
          </ul>
          <div class="children_box" v-if="index == 1">
            <div class="htmlBox" @click="more(dataInfo.navigationName, dataInfo.id, dataInfo.navigationType)"
              v-html="details.text"></div>
          </div>
          <div class="children_box" v-else>
            <ul class="children" v-for="(date, idx) in item.witemVo" :key="idx"
              :class="date.isCheck ? 'itemActive' : ''">
              <template v-for="(dates, idxs) in date.journalism" @key="idxs">
                <li v-if="idxs < 6" @click="goDetails(item.navigationName, dates.id)" :key="idxs">
                  <div class="title">{{ dates.journalisTitle }}</div>
                  <div class="time">{{ toDate(dates.createTime, "yyyy-MM-dd") }}</div>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>

      <div class="threeImg">
        <div class="title" :style="'background:' + color">精彩图库</div>
        <div class="Containt">
          <ul :style="{ left: calleft + 'px', width: widthData + 'px' }" v-on:mouseover="stopMove()"
            v-on:mouseout="move()" class="imgBoxoul">
            <li v-for="(item, index) in mapDepotList" :key="index" ref="lis">
              <el-image class="img" :src="echoImg(item.bannerUrl)" @click="goDetails('', item.id)"
                fit="cover"></el-image>
            </li>
            <li v-for="(dates, idx) in mapDepotList" :key="'c_' + idx" ref="lis1">
              <el-image class="img" :src="echoImg(dates.bannerUrl)" @click="goDetails('', dates.id)"
                fit="cover"></el-image>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  selectLink,
  selectMapDepot,
  selectBanner,
  getNavigationTwoList,
  getNavigationTemplate,
  websiteListGetJournalismId,
  getTextarea,
} from "@/api/template";
export default {
  name: "Index",
  components: {},
  data() {
    return {
      linksList: [],
      mapDepotList: [],
      bannerList: [],
      subjectList: [],
      liBoxML: 0,
      childrenList: [],
      calleft: 0,
      speed: 1,
      timer: "",
      dataInfo: {},
      details: {},
    };
  },
  created() {
    this.init();
    this.move();
    this.$store.commit("hideLoading");
  },
  computed: {
    widthData() {
      return 320 * Number(this.mapDepotList.length * 2);
    },
    ...mapState(["color"]),
  },
  mounted() {
    let imgBox = document.getElementsByClassName("imgBoxoul")[0];
    imgBox.innerHTML += imgBox.innerHTML;
  },
  methods: {
    init() {
      this.getSelectBanner();
      this.getNavigationTwoList();
      this.getSelectMapDepot();
      // this.getSelectLink()
      this.initData()
    },
    initData() {
      let self = this;
      let tid = localStorage.getItem('Tid')
      getNavigationTemplate(tid).then((res) => {
        console.log(res.data)
        if (res.code == 200) {
          this.dataInfo = res.data.filter(e => e.navigationName == '场馆协会')[0]
          websiteListGetJournalismId(this.dataInfo.id).then((res) => {
            this.details = res.data;
            this.getTextareaFun(res.data.journalisContent);
          }).catch(() => { });
        } else {
          self.$message(res.msg);
        }
      }).catch(() => { });
    },
    getTextareaFun(journalisContent) {
      let params = {
        journalisContent: journalisContent,
      };
      getTextarea(params).then((res) => {
        this.details['text'] = res.data.dataHtml;
        console.log(this.details)
      });
    },
    getSelectLink() {
      let self = this;
      selectLink().then((res) => {
        if (res.code == 200) {
          this.linksList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => { });
    },
    getSelectBanner() {
      let self = this;
      selectBanner().then((res) => {
        if (res.code == 200) {
          this.bannerList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => { });
    },
    getSelectMapDepot() {
      let self = this;
      selectMapDepot().then((res) => {
        if (res.code == 200) {
          this.mapDepotList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => { });
    },
    getNavigationTwoList() {
      let self = this;
      getNavigationTwoList().then((res) => {
        if (res.code == 200) {
          res.data.forEach((dates) => {
            dates.witemVo.forEach((item, index) => {
              if (index == 0) {
                item.isCheck = true;
                this.childrenList = item;
              } else {
                item.isCheck = false;
              }
              item.journalism.forEach((its, idx) => {
                if (idx == 0) {
                  its.isCheck = true;
                } else {
                  its.isCheck = false;
                }
              });
            });
          });
          this.subjectList = res.data;
        } else {
          self.$message(res.msg);
        }
      }).catch(() => { });
    },
    openLinks(linkUrl) {
      window.open(linkUrl, "_blank");
    },
    more(navigationName, id, type) {
      let data = {
        name: navigationName,
        id: id,
        type: type,
      };
      window.sessionStorage.setItem("navigationValue", JSON.stringify(data));
      // this.$router.push({
      //   params: { name: navigationName, id: id },
      //   name: "second-list",
      // });
      this.$router.push({ params: { name: navigationName, id: id, type: type, index: '0' }, name: 'second-list' })
    },
    goDetails(navigationName, id) {
      let data = {
        name: navigationName,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/second-details");
    },
    changeActive(index, idx) {
      this.subjectList[index].witemVo.forEach((item, num) => {
        if (idx == num) {
          item.isCheck = true;
          this.childrenList = item;
        } else {
          item.isCheck = false;
        }
      });
    },
    move() {
      this.timer = setInterval(this.starMove, 20);
    },
    //开始移动
    starMove() {
      this.calleft -= 1.2; //*this.speed
      if (this.calleft <= -this.mapDepotList.length * 320) {
        this.calleft = 0;
      }
    },
    //鼠标悬停时停止移动
    stopMove() {
      clearInterval(this.timer);
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 0 auto;

  .main_box {
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    font-size: 16px;

    .banner {
      width: 576px;
      height: 310px;
      margin-top: 20px;

      .img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .title {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 99;
        color: #fff;
        height: 56px;
        line-height: 56px;
        padding: 0 20px;
      }
    }

    .listBox {
      height: 310px;
      margin-top: 20px;
      float: left;
      overflow-y: hidden;
      background: #fff;

      .ul {
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .li {
          display: flex;
          //flex-flow:row wrap;
          justify-content: start;
          white-space: nowrap;
          overflow-x: hidden;

          li {
            padding: 0 20px;
            cursor: pointer;
          }

          .active {
            color: #fff;
            background: #4ab344;
          }

          li:hover {
            color: #fff;
            background: #4ab344;
          }
        }

        .more {
          width: 100px;
          float: right;
          text-align: right;
          font-size: 12px;
          cursor: pointer;
          padding-right: 16px;
          box-sizing: border-box;
        }
      }

      .children_box {
        position: relative;

        .htmlBox {
          width: 100%;
          height: 250px;
          padding: 10px 10px 0;
          box-sizing: border-box;
          overflow: hidden;
          cursor: pointer;
        }

        .children {
          display: none;
          padding: 0 10px;

          li {
            height: 40px;
            line-height: 40px;
            border-bottom: 1px dashed #e8e8e8;
            cursor: pointer;

            .title {
              float: left;
              width: 70%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .time {
              float: right;
              width: 30%;
              text-align: right;
            }
          }

          li:last-child {
            border-bottom: none;
          }
        }

        .itemActive {
          display: block;
          animation: myMou 1.2s ease-out;
          animation-fill-mode: forwards;
        }
      }
    }

    .list_box0 {
      width: 604px;
      margin-left: 20px;

      .ul {
        border-bottom: 1px solid #4ab344;
      }

      .li {
        width: 500px;

        li {
          width: 230px;
          text-align: center;
        }
      }
    }

    .list_box1,
    .list_box6,
    .list_box11 {
      width: 320px;

      .ul {
        border-bottom: 1px solid #4ab344;
      }

      .li {
        width: 220px;
      }
    }

    .list_box2,
    .list_box7,
    .list_box12 {
      width: 520px;
      margin-left: 12px;
      margin-right: 12px;

      .ul {
        border-bottom: 1px solid #4ab344;
      }

      .li {
        width: 420px;
      }
    }

    .list_box3,
    .list_box8,
    .list_box13 {
      width: 336px;

      .ul {
        border-bottom: 1px solid #4ab344;
      }

      .li {
        width: 236px;
      }
    }

    .list_box4,
    .list_box9,
    .list_box14 {
      width: 598px;

      .ul {
        border-bottom: 1px solid #4ab344;
      }

      .li {
        width: 498px;
      }
    }

    .list_box5,
    .list_box10,
    .list_box15 {
      width: 588px;
      margin-left: 12px;

      .ul {
        border-bottom: 1px solid #4ab344;
      }

      .li {
        width: 488px;
      }
    }
  }
}

.listBox {
  position: relative;
}

.threeImg {
  position: relative;
  background: #fff;
  margin-top: 20px;

  .title {
    height: 45px;
    line-height: 45px;
    color: #fff;
    background: #4ab344;
    padding: 0 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 16px;
  }

  .Containt {
    position: relative;
    padding: 20px 0;
    overflow-y: auto;
    width: 1200px;
    height: 190px;
    overflow: hidden;
    margin: 0 auto;

    ul {
      margin: 0 auto;
      width: 1200px;
      height: 190px;
      position: absolute;
      left: 0px;
      cursor: pointer;
      z-index: 10;
      overflow: hidden;

      li {
        float: left;
        width: 300px;
        height: 190px;
        margin-left: 20px;
        overflow: hidden;
        background-color: #ffffff;

        .img {
          width: 300px;
          height: 190px;
          cursor: pointer;
        }
      }
    }
  }
}

@keyframes myMou {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
