import { render, staticRenderFns } from "./professionalInformation.vue?vue&type=template&id=82356904&scoped=true"
import script from "./professionalInformation.vue?vue&type=script&lang=js"
export * from "./professionalInformation.vue?vue&type=script&lang=js"
import style0 from "./professionalInformation.vue?vue&type=style&index=0&id=82356904&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82356904",
  null
  
)

export default component.exports