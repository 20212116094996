import { render, staticRenderFns } from "./fifth.vue?vue&type=template&id=af701b52&scoped=true"
import script from "./fifth.vue?vue&type=script&lang=js"
export * from "./fifth.vue?vue&type=script&lang=js"
import style0 from "./fifth.vue?vue&type=style&index=0&id=af701b52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af701b52",
  null
  
)

export default component.exports