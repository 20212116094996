<template>
  <div>
    <div class="logo">
      <div class="logo_top">
        <el-image v-if="getTenantData" class="img" :src="echoImg(getTenantData.icon)"></el-image>
      </div>
<!--      <ul class="userBox" v-if="token">-->
<!--        <li @click="loginAdmin">进入后台</li>-->
<!--        <li @click="logouts">退出登录</li>-->
<!--      </ul>-->
<!--      <ul class="userBox" v-else>-->
<!--        <li @click="login(3)">注册</li>-->
<!--        <li @click="login(1)">登录</li>-->
<!--      </ul>-->
    </div>
  </div>
</template>
<script>
import { getTenantById,websiteTemplate,getTenantInfo } from "@/api/template";
import { logout } from "@/api/user";
import { mapState } from "vuex";
export default {
  name: "TemplateHeader",
  components: {},
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      activeIndex: 0,
      navsList: [],
      getTenantData: '',
      token:'',
      loginCode:''
    };
  },
  created(){
    // this.getGetTenantInfo()
    this.getGetTenant();
    this.token = window.localStorage.getItem('token')
  },
  methods: {
    init(tid){
      websiteTemplate(tid).then((res) => {
        // this.color = res.data.colour;
        this.$store.dispatch("saveColor", res.data.colour);
      });
    },
    getGetTenantInfo() {
      let dname = window.location.host;
      getTenantInfo({ code: dname }).then((res) => {
        if (res.data) {
          window.localStorage.setItem("domainTid", res.data.tenantId);
          this.getGetTenant();
        }
      });
    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
    changeP(e){
      e.currentTarget.className = "activeP"
    },
    removeP(e){
      e.currentTarget.className = ""
    },
    getGetTenant(){
      let that = this
      getTenantById(document.domain).then(res =>{
        if(res.code == 200){
          this.getTenantData = res.data
          this.init(res.data.tid)
          window.localStorage.setItem("tenant_id",res.data.tenantId)
          window.localStorage.setItem("loginCode",res.data.loginCode)
          this.loginCode = res.data.loginCode
          document.title = res.data.loginTitle
          if (res.data.icon) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = that.echoImg(res.data.icon);
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    handleSelect(){

    },
    login(active){
      if(this.loginCode){
        window.location.href = 'http://' + this.loginCode +'/login?redirect='+active;
      }else {
        window.location.href = process.env.VUE_APP_SAAS_URL +'/login?redirect='+active;
      }
    },
    loginAdmin(){
      if(this.loginCode){
        window.location.href = 'http://' + this.loginCode + '/home'
      }else {
        window.location.href = process.env.VUE_APP_SAAS_URL
      }
    },
    logouts() {
      let _token = window.localStorage.getItem("token");
      logout(_token).then(() => {
        this.token = ''
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        this.$store.dispatch("saveUser", []);
        this.$store.dispatch("saveHeadActive", "/index");
        this.$store.dispatch("saveSideActive", "/user");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.logo{
  width: 100%;
  margin: 0 auto;
  position: relative;
  //padding: 30px 0;
  //background: url("../images/header-backgroud.png") no-repeat;
  .logo_top{
    width: 1230px;
    margin: 0 auto;
    height: 158px;
    overflow: hidden;
    .img{
      max-width: 100%;
      height: 126px;
      margin-top: 20px;
    }
    .auth-login{
      position: absolute;
      top: 20px;
      right: 10px;
      color: #0000ee;
      cursor: pointer;
      li{
        width: 60px;
        float: left;
        a{
          color: #1989fa;
        }
        :hover{
          background: #1e3c5a;
        }
      }
    }
  }
  .loginAdmin{
    position: absolute;
    right: 20px;
    top: 55px;
    color: #0000ee;
    cursor: pointer;
  }
  .userBox{
    position: absolute;
    right: 10px;
    top: 20px;
    color: #fff;
    cursor: pointer;
    li{
      float: left;
      margin: 0 10px;
    }
  }
}
.navbar{
  width: 1230px;
  height: 45px;
  line-height: 45px;
  background: #D30212;
  margin: 0 auto;
  //border-bottom: 10px #003366 solid;
  .ul{
    width: 1230px;
    display: flex;
    flex-flow:row wrap;
    justify-content: start;
    margin: 0 auto;
    color: #fff;
    li{
      flex: 1;
      position: relative;
      height: 45px;
      line-height: 45px;
      //padding: 0 10px;
      text-align: center;
      span{
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
      .menu{
        width: 120px;
        height: auto;
        position: absolute;
        top: 45px;
        left: 0px;
        //border: 1px #d9d9d9 solid;
        background-color: #fff;
        display: none;
        z-index: 9;
        //box-shadow: #ccc 0px 0px 10px;
        display: none;
        overflow: hidden;
        p{
          text-align: left;
          padding-left: 20px;
          line-height: 30px;
          color: #5c5c5c;
          cursor: pointer;
          span{
            //color: #cc3333;
            display: inline-block;
            cursor: pointer;
            color: #5c5c5c;
            width: 100%;
          }
        }
        .activeP{
          background: #ED7D03;
          span{
            color: #fff;
          }
        }
      }
    }
    .active{
      background: #ED7D03;
      .menu{
        display: block;
        animation: myMou 1s ease-out;
        animation-fill-mode: forwards;
      }
    }
  }
}
@keyframes myMou {
  from { max-height: 0px; }
  to { max-height: 500px; }
}
</style>
