<template>
  <div>
    <div class="logo">
      <div class="logo_top">
        <img v-if="getTenantData" class="img" :src="echoImg(getTenantData.icon)" style="width: auto;height: 3rem;"/>
      </div>
<!--      <ul class="userBox" v-if="token">-->
<!--&lt;!&ndash;        <li @click="loginAdmin">进入后台</li>&ndash;&gt;-->
<!--        <li @click="logouts">退出登录</li>-->
<!--      </ul>-->
<!--      <ul class="userBox" v-else>-->
<!--        <li @click="login(3)">注册</li>-->
<!--        <li @click="login(1)">登录</li>-->
<!--      </ul>-->
    </div>
    <div class="navbar" :style="'background:'+ color">
      <div class="cont">
        <ul class="ul" v-if="navsList">
          <li><span class="text_a" @click="goIndex"> 首页 </span></li>
          <template v-for="(item,index) in navsList" @key="index">
            <li v-if="index<9" v-on:mouseover="changeActive($event,item.witem.length)"
                v-on:mouseout="removeActive($event,item.witem.length)" :key="index">
              <span class="text_a" @click="golink(item.navigationName,item.id,item.navigationType)"> {{item.navigationName}} </span>
              <div class="menu" v-if="item.witem.length>0" >
                <p v-for="(dates,idx) in item.witem" :key="idx" v-on:mouseover="changeP($event)" v-on:mouseout="removeP($event)">
                  <span @click="golink(item.navigationName,dates.id,dates.navigationType,idx)"> {{dates.navigationName}} </span>
                </p>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getTenantById,getNavigationTemplateApp,websiteTemplate,getTenantInfo } from "@/api/template";
import { logout } from "@/api/user";
import { mapState } from "vuex";
export default {
  name: "TemplateHeader",
  components: {},
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      activeIndex: 0,
      navsList: [],
      getTenantData: '',
      token:'',
      loginCode:''
    };
  },
  created(){
    // this.getGetTenantInfo()
    this.getGetTenant();
    this.token = window.localStorage.getItem('token')
  },
  methods: {
    init(tid){
      let self = this
      self.navsList = []
      getNavigationTemplateApp(tid).then(res =>{
        if(res.code == 200){
          self.navsList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
      websiteTemplate(tid).then((res) => {
        // this.color = res.data.colour;
        this.$store.dispatch("saveColor", res.data.colour);
      }).catch(()=>{})
    },
    getGetTenantInfo() {
      let dname = window.location.host;
      getTenantInfo({ code: dname }).then((res) => {
        if (res.data) {
          window.localStorage.setItem("domainTid", res.data.tenantId);
          this.getGetTenant();
        }
      });
    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
    changeP(e){
      e.currentTarget.className = "activeP"
    },
    removeP(e){
      e.currentTarget.className = ""
    },
    goIndex(){
      this.$router.push("/template-eighthMobile");
    },
    getGetTenant(){
      let that = this
      getTenantById(document.domain).then(res =>{
        if(res.code == 200){
          this.getTenantData = res.data
          this.init(res.data.tid)
          window.localStorage.setItem("tenant_id",res.data.tenantId)
          window.localStorage.setItem("loginCode",res.data.loginCode)
          this.loginCode = res.data.loginCode
          document.title = res.data.loginTitle
          if (res.data.icon) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = that.echoImg(res.data.icon);
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    handleSelect(){

    },
    golink(navigationName,id,type) {
      // let data = {
      //   name: navigationName,
      //   id: id,
      //   type: type,
      //   index:index
      // }
      // window.sessionStorage.setItem('navigationValue', JSON.stringify(data))
      if(this.$route.name == 'eighthMobile-list'){
        // this.$router.push({params: {name:navigationName,id:id},name:'sixth-transfer'})
        this.$router.push({params: {name:navigationName,id:id,type: type},name:'eighthMobile-transfer'})
      }else {
        // this.$router.push({params: {name:navigationName,id:id},name:'eighthMobile-list'})
        this.$router.push({params: {name:navigationName,id:id,type: type},name:'eighthMobile-list'})
      }
    },
    login(active){
      if(this.loginCode){
        window.location.href = 'http://' + this.loginCode +'/login?redirect='+active;
      }else {
        window.location.href = process.env.VUE_APP_SAAS_URL +'/login?redirect='+active;
      }
    },
    loginAdmin(){
      if(this.loginCode){
        window.location.href = 'http://' + this.loginCode + '/home'
      }else {
        window.location.href = process.env.VUE_APP_SAAS_URL
      }
    },
    logouts() {
      let _token = window.localStorage.getItem("token");
      logout(_token).then(() => {
        this.token = ''
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        this.$store.dispatch("saveUser", []);
        this.$store.dispatch("saveHeadActive", "/index");
        this.$store.dispatch("saveSideActive", "/user");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.logo{
  width: 100%;
  margin: 0 auto;
  position: relative;
  //padding: 30px 0;
  background: url("../images/headBg_2.png") no-repeat;
  .logo_top{
    width: 23rem;
    margin: 0 auto;
    height: 4rem;
    .img{
      max-width: 100%;
      height: 3rem;
      margin-top: .5rem;
    }
    .auth-login{
      position: absolute;
      top: 20px;
      right: 10px;
      color: #0000ee;
      cursor: pointer;
      li{
        width: 60px;
        float: left;
        a{
          color: #1989fa;
        }
        :hover{
          background: #1e3c5a;
        }
      }
    }
  }
  .loginAdmin{
    position: absolute;
    right: 20px;
    top: 55px;
    color: #0000ee;
    cursor: pointer;
  }
  .userBox{
    position: absolute;
    right: .1rem;
    top: 1rem;
    color: #fff;
    cursor: pointer;
    font-size: .5rem;
    li{
      float: left;
      margin: 0 10px;
    }
  }
}
.navbar{
  //width: 100%;
  //height: 2rem;
  //line-height: 2rem;
  //background: #D30212;
  //overflow-x: auto;
  //overflow-y: hidden;
  //border-bottom: 10px #003366 solid;
  width: 100%;
  overflow: hidden;
  height: 2rem;
  .cont{
    width: 24rem;
    height: 3rem;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y:hidden ;
    position: relative;
  }
  .ul{
    left: 0px;
    top: 0px;
    position: absolute;
    height: 2rem;
    line-height: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    //padding-right: 1rem;
    //width: 130%;
    //height: 2rem;
    //display: flex;
    //flex-flow:row wrap;
    //justify-content: start;
    //margin: 0 auto;
    color: #fff;
    li{
      display: inline-block;
      float: left;
      //font-size: 13px;
      text-decoration: none;
      margin-right: 1rem;
      padding: 0 .5rem;
      ////flex: 1;
      ////float: left;
      //float: left;
      //margin-left: 1rem;
      //position: relative;
      //height: 2rem;
      //line-height: 2rem;
      ////padding: 0 10px;
      //text-align: center;
      ////overflow: hidden;
      span{
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
      }
      .menu{
        width: 120px;
        height: auto;
        position: absolute;
        top: 45px;
        left: 0px;
        //border: 1px #d9d9d9 solid;
        background-color: #fff;
        display: none;
        z-index: 9999;
        //box-shadow: #ccc 0px 0px 10px;
        //display: none;
        overflow: hidden;
        p{
          text-align: left;
          padding-left: 20px;
          line-height: 30px;
          color: #5c5c5c;
          cursor: pointer;
          span{
            //color: #cc3333;
            display: inline-block;
            cursor: pointer;
            color: #5c5c5c;
            width: 100%;
          }
        }
        .activeP{
          background: #109345;
          span{
            color: #fff;
          }
        }
      }
    }
    .active{
      background: #109345;
      .menu{
        display: block;
        animation: myMou 1s ease-out;
        animation-fill-mode: forwards;
      }
    }
  }
}
@keyframes myMou {
  from { max-height: 0px; }
  to { max-height: 500px; }
}
</style>
