<template>
  <div>
    <div class="top" :style="'background:' + color">
      <div class="top_box">
        <!--        <div class="loginAdmin" @click="loginAdmin">进入后台</div>-->
        <ul class="userBox" v-if="token">
          <li @click="loginAdmin">进入后台</li>
          <li @click="logouts">退出登录</li>
        </ul>
        <ul class="userBox" v-else>
          <li @click="login(3)">注册</li>
          <li @click="login(1)">登录</li>
        </ul>
        <!--        <div class="top_box_left">-->
        <!--          <ul>-->
        <!--            <li><a href="javascript:;">登录</a> /li>-->
        <!--            <li><a href="javascript:;">关于我们</a></li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="logo">
      <el-image v-if="getTenantData" class="img" :src="echoImg(getTenantData.icon)"></el-image>
    </div>
    <div class="navbar">
      <ul class="ul">
        <li :class="current == -100 || current == undefined ? 'on' : ''"
          :style="current == -100 || current == undefined ? 'border-bottom: 2px solid' + color : ''">
          <span class="text_a" @click="goIndex" :style="current == -100 ? 'color:' + color : ''">首页</span>
        </li>
        <template v-for="(item, index) in navsList" @key="index">
          <li v-if="index < 9" :class="current == index ? 'on' : ''"
            :style="current == index ? 'border-bottom: 2px solid' + color : ''">
            <span class="text_a" :style="current == index ? 'color:' + color : ''"
              @click=" golink(item.navigationName, item.id, item.navigationType, index)">{{ item.navigationName
              }}</span>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  getTenantById,
  getTenantInfo,
  websiteTemplate,
  navigation,
} from "@/api/template";
import { logout } from "@/api/user";
export default {
  name: "TemplateHeader",
  components: {},
  computed: {
    ...mapState(["color"]),
    currentActive() {
      const route = this.$route
      const { path } = route
      return path
    }
  },
  watch: {
    currentActive(newValue, oldValue) {
      let newCurrent = newValue.split('/')[5]
      let oldCurrent = oldValue.split('/')[5]
      if (newCurrent != oldCurrent) {
        this.current = newCurrent
      }
    },
  },
  data() {
    return {
      navsList: [],
      current: window.sessionStorage.getItem("currentActive") || -100,
      getTenantData: "",
      token: "",
      loginCode: "",
    };
  },
  created() {
    // this.getGetTenantInfo();
    this.getGetTenant()
    this.token = window.localStorage.getItem("token");
  },
  methods: {
    init(tid) {
      let self = this;
      navigation().then(res => {
        if (res.code == 200) {
          let data = res.data.navigation.filter(e => e.hasContent)
          data.forEach((item) => {
            item.isCheck = false;
          });
          this.navsList = data;
        } else {
          self.$message(res.msg);
        }
      })
      websiteTemplate(tid).then((res) => {
        this.$store.dispatch("saveColor", res.data.colour);
      });
    },
    getGetTenantInfo() {
      let dname = window.location.host;
      getTenantInfo({ code: dname }).then((res) => {
        if (res.data) {
          window.localStorage.setItem("domainTid", res.data.tenantId);
          this.getGetTenant();
        }
      });
    },
    getGetTenant() {
      let that = this
      getTenantById(document.domain).then((res) => {
        if (res.code == 200) {
          this.getTenantData = res.data;
          this.init(res.data.tid);
          window.localStorage.setItem("tenant_id", res.data.tenantId);
          window.localStorage.setItem("loginCode", res.data.loginCode);
          this.loginCode = res.data.loginCode;
          document.title = res.data.loginTitle
          if (res.data.icon) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = that.echoImg(res.data.icon);
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        } else {
          self.$message(res.msg);
        }
      }).catch(() => { });
    },
    goIndex() {
      this.current = -100;
      this.$router.push("/template-second2");
      window.sessionStorage.setItem("currentActive", "-100");
    },
    golink(navigationName, id, type, index) {
      this.current = index;
      let data = {
        name: navigationName,
        id: id,
        type: type,
      };
      window.sessionStorage.setItem("currentActive", index);
      window.sessionStorage.setItem("navigationValue", JSON.stringify(data));
      // if (this.$route.name == "second-list") {
      //   this.$router.push({
      //     params: { name: navigationName, id: id },
      //     name: "second-transfer",
      //   });
      // } else {
      //   this.$router.push({
      //     params: { name: navigationName, id: id },
      //     name: "second-list",
      //   });
      // }
      if (this.$route.name == 'second2-list') {
        this.$router.push({ params: { name: navigationName, id: id, type: type, index: index }, name: 'second2-transfer' })
      } else {
        this.$router.push({ params: { name: navigationName, id: id, type: type, index: index }, name: 'second2-list' })
      }
    },
    login(active) {
      if (this.loginCode) {
        window.location.href =
          "http://" + this.loginCode + "/login?redirect=" + active;
      } else {
        window.location.href =
          process.env.VUE_APP_SAAS_URL + "/login?redirect=" + active;
      }
    },
    loginAdmin() {
      window.sessionStorage.setItem("currentActive", "-100");
      if (this.loginCode) {
        window.location.href = "http://" + this.loginCode + "/home";
      } else {
        window.location.href = process.env.VUE_APP_SAAS_URL;
      }
    },
    logouts() {
      let _token = window.localStorage.getItem("token");
      logout(_token).then(() => {
        this.token = "";
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        this.$store.dispatch("saveUser", []);
        this.$store.dispatch("saveHeadActive", "/index");
        this.$store.dispatch("saveSideActive", "/user");
        // if(this.loginCode){
        //   window.location.href = 'http://' + this.loginCode
        // }else {
        //   window.location.href = process.env.VUE_APP_SAAS_URL
        // }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  height: 40px;
  background: #4ab344;

  .top_box {
    position: relative;
    width: 1200px;
    margin: 0px auto;
    font-size: 14px;

    .loginAdmin {
      position: absolute;
      right: 20px;
      top: 10px;
      color: #fff;
      cursor: pointer;
    }

    .userBox {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #fff;
      cursor: pointer;

      li {
        float: left;
        margin: 0 10px;
      }
    }

    .top_box_left {
      float: left;

      li {
        float: left;
        line-height: 40px;

        a {
          color: #fff;
          display: block;
          padding: 0px 10px;
          font-size: 14px;
        }
      }
    }

    .top_box_right {
      float: right;
      border: 1px #eee solid;
      padding: 2px 10px;
      margin-top: 4px;
      border-radius: 15px;
      background-color: #fff;

      .input_1 {
        float: left;
        border: 0px;
        height: 18px;
        line-height: 18px;
        width: 180px;
      }

      .top_box_right .input_2 {
        float: left;
        border: 0;
        //background: url(../../images/search.png) no-repeat;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }
}

.logo {
  width: 1200px;
  margin: 12px auto 0;

  .img {
    height: 76px;
  }
}

.navbar {
  width: 100%;
  height: 40px;

  .ul {
    width: 1200px;
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    margin: 0 auto;
    color: #fff;

    li {
      flex: 1;
      position: relative;
      height: 40px;
      line-height: 40px;
      //padding: 0 10px;
      text-align: center;

      span {
        color: #8e8e8e;
        font-size: 16px;
        cursor: pointer;
      }

      .menu {
        width: 220px;
        height: auto;
        max-height: 0;
        position: absolute;
        top: 40px;
        left: -20px;
        background-color: #fff;
        display: none;
        z-index: 9;
        box-shadow: #ccc 0px 0px 10px;
        display: none;
        overflow: hidden;

        p {
          text-align: left;
          padding-left: 20px;
          line-height: 50px;
          color: #5c5c5c;
          cursor: pointer;

          span {
            //color: #cc3333;
            display: inline-block;
            cursor: pointer;
            color: #5c5c5c;
            width: 100%;
          }
        }
      }
    }

    .on {
      border-bottom: 2px #4ab344 solid;

      .text_a {
        color: #4ab344;
      }
    }

    .active {
      border-bottom: 2px #4ab344 solid;

      .text_a {
        color: #4ab344;
      }

      .menu {
        display: block;
        animation: myMou 1s ease-out;
        animation-fill-mode: forwards;
      }
    }
  }
}

.imageBox {
  width: 1200px;
  height: 130px;
  margin: 10px auto 0;

  .img {
    width: 100%;
  }
}

@keyframes myMou {
  from {
    max-height: 0px;
  }

  to {
    max-height: 500px;
  }
}
</style>
